<template>
  <div>
    <search-system-product @submit="submitSearch" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="name" slot-scope="name,record">
        <router-link :to="{name:'system_products_info',params:{id: record.id}}">{{ name }}</router-link>
      </template>
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0 " class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="deleteProduct(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增套餐模态框 -->
    <new-system-product
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑套餐模态框 -->
    <edit-system-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchSystemProduct from '@/views/products/system/Search'
import PreviewImage from '@/components/PreviewImage'
import { findSystemProductList, updateSystemProductEffective, deleteSystemProduct } from '@/api/product'

export default {
  name: 'SystemProductList',
  components: {
    Pagination,
    PreviewImage,
    SearchSystemProduct,
    NewSystemProduct: () => import('@/views/products/system/New'),
    EditSystemProduct: () => import('@/views/products/system/Edit')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 120,
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '型号',
          width: 120,
          ellipsis: true,
          dataIndex: 'product_model'
        },
        {
          title: '项目代码',
          width: 100,
          ellipsis: true,
          dataIndex: 'code'
        },
        {
          title: '拼音码',
          width: 100,
          ellipsis: true,
          dataIndex: 'pinyin'
        },
        {
          title: '状态',
          width: 120,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '类型',
          width: 90,
          ellipsis: true,
          dataIndex: 'product_type'
        },
        {
          title: '是否惠民赠送',
          width: 120,
          dataIndex: 'str_benefit_the_people'
        },
        {
          title: '销售范围',
          width: 160,
          ellipsis: true,
          dataIndex: 'str_sales_scope'
        },
        {
          title: '销售渠道',
          width: 160,
          ellipsis: true,
          dataIndex: 'str_sales_channel'
        },
        {
          title: '产生任务',
          width: 120,
          ellipsis: true,
          dataIndex: 'str_tasks'
        },
        {
          title: '规格数量',
          width: 120,
          ellipsis: true,
          dataIndex: 'item_count'
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          ellipsis: true,
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '产品介绍',
          width: 150,
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 180,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 180,
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSystemProductList(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将套餐生效吗?' : '确定失效套餐吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateSystemProductEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    deleteProduct(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteSystemProduct(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
